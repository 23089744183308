import React, { useState, useEffect } from 'react';
import './App.css';
import photo from './photo.png';
import photoLight from './photoLihght.png';
const App = () => {
  if (!document.cookie) {
    if (!window.confirm('We use cookies to store information about the current theme. If you do not agree, please leave this page. Do you accept cookies?')) {
      window.location.href = 'about:blank';
    } else {
      document.cookie = "theme=light; expires=" + new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000).toUTCString() + "; path=/";
    }
  }
  const [page, setPage] = useState('main');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [theme, setTheme] = useState(document.cookie.split('; ').find(row => row.startsWith('theme=')).split('=')[1]);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const renderContactsSection = () => (
    <div className="container" id="container">
      <h1>Contacts</h1>
      <div style={{ display: 'flex', gap: '2vw', flexWrap: 'wrap', justifyContent: 'center' }}>
        <a href='mailto:primelog@primelog.org' style={{ textDecoration: "none" }}>
          <button style={{ display: 'flex', alignItems: 'center', gap: '0.5em', border: 'none', padding: '1em', cursor: 'pointer' }}>
            <img src="https://cdn-icons-png.flaticon.com/512/665/665072.png" width={15} alt="Mail" />
            Mail
          </button>
        </a>

        <a href='https://t.me/primelog_bot' target='blank' style={{ textDecoration: "none" }}>
          <button style={{ display: 'flex', alignItems: 'center', gap: '0.5em', border: 'none', padding: '1em', cursor: 'pointer' }}>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/2048px-Telegram_2019_Logo.svg.png" width={15} alt="Telegram" />
            Telegram
          </button>
        </a>
      </div><br />
      <p>If nothing happens when clicking on Mail, you can send an email to primelog@primelog.org</p>
    </div>
  );

  const renderSocialsSection = () => (
    <div className="container" id="container">
      <h1>My social networks</h1>
      <div style={{ display: 'flex', gap: '2vw', flexWrap: 'wrap', justifyContent: 'center' }}>
        <a href='https://github.com/primelogdev/' target='blank' style={{ textDecoration: "none" }}>
          <button style={{ display: 'flex', alignItems: 'center', gap: '0.5em', border: 'none', padding: '1em', cursor: 'pointer' }}>
            <img src="https://cdn-icons-png.flaticon.com/512/25/25231.png" width={15} alt="Mail" />
            Github
          </button>
        </a>
        <a href='https://t.me/primelog/' target='   blank' style={{ textDecoration: "none" }}>
          <button style={{ display: 'flex', alignItems: 'center', gap: '0.5em', border: 'none', padding: '1em', cursor: 'pointer' }}>
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Telegram_2019_Logo.svg/2048px-Telegram_2019_Logo.svg.png" width={15} alt="Telegram" />
            Telegram
          </button>
        </a>
      </div><br />
    </div>
  );

  const renderProjectsSection = () => (
    <>
      <h1 style={{ textAlign: "center" }}>Soon...</h1>
      {/* <div className="container" id="container">
        <h2>WebUntis Telegram Bot <a href='https://github.com/primelogdev/' target='blank' style={{ textDecoration: "none" }}>
          <button style={{ background: "none" }}>
            <img src="https://cdn-icons-png.flaticon.com/512/25/25231.png" width={20} alt="Mail" />
          </button>
        </a>
        </h2>
        <p>Very avesome project bla bla bla</p>
      </div>
      <div className="container" id="container">
        <h2>WebUntis Telegram Bot <a href='https://github.com/primelogdev/' target='blank' style={{ textDecoration: "none" }}>
          <button style={{ background: "none" }}>
            <img src="https://cdn-icons-png.flaticon.com/512/25/25231.png" width={20} alt="Mail" />
          </button>
        </a>
        </h2>
        <p>Very avesome project bla bla bla</p>
      </div>
      <div className="container" id="container">
        <h2>WebUntis Telegram Bot <a href='https://github.com/primelogdev/' target='blank' style={{ textDecoration: "none" }}>
          <button style={{ background: "none" }}>
            <img src="https://cdn-icons-png.flaticon.com/512/25/25231.png" width={20} alt="Mail" />
          </button>
        </a>
        </h2>
        <p>Very avesome project bla bla bla</p>
      </div> */}
    </>
  );

  return (
    <div className="app-container">
      <header className="app-header">
        <div className="burger-menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <div className={`burger-bar ${isMenuOpen ? 'open' : ''}`}></div>
          <div className={`burger-bar ${isMenuOpen ? 'open' : ''}`}></div>
          <div className={`burger-bar ${isMenuOpen ? 'open' : ''}`}></div>
        </div>
        <nav className={`header-nav ${isMenuOpen ? 'open' : ''}`}>
          <a
            href="/#"
            className={`link ${page === 'main' ? "active" : ""}`}
            onClick={() => setPage('main')}
          >
            About me
          </a>
          <a
            href="/#"
            className={`link ${page === 'projects' ? "active" : ""}`}
            onClick={() => setPage('projects')}
          >
            Projects
          </a>
          <a
            href="/#"
            className={`link ${page === 'social' ? "active" : ""}`}
            onClick={() => setPage('social')}
          >
            Social networks
          </a>
          <a
            href="/#"
            className={`link ${page === 'contacts' ? "active" : ""}`}
            onClick={() => setPage('contacts')}
          >
            Contacts
          </a>
          <button
            style={{ background: "none" }}
            onClick={() => {
              const curTheme =
                document.cookie.split('; ').find(row => row.startsWith('theme=')).split('=')[1] === 'light' ? 'night' : 'light';
              setTheme(curTheme);
              document.cookie = `theme=${curTheme}; expires=` + new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000).toUTCString() + "; path=/";
            }}
          >
            {theme === 'light' ? <i className="fa-solid fa-moon" style={{ color: "black" }}></i> : <i className="fa-solid fa-sun"></i>}
          </button>
        </nav>
      </header>

      {
        page === 'contacts' ? (
          renderContactsSection()
        ) : page === 'main' ? (
          <div className="container" id="container">
            <img src={theme === 'light' ? photo : photoLight} alt='name' className='photo'></img>
            <h1>Primelog</h1>
            <p>Hi there! I`m 13 y.o node.js developer(beginner). I speak <span className='emoji'>🇬🇧</span>English and <span className='emoji'>🇩🇪</span>German.</p>
          </div>
        ) : page === 'projects' ? (
          renderProjectsSection()
        ) : page === 'social' ?
          renderSocialsSection()
          : ''
      }
    </div >
  );
};

export default App;
